<template>
  <div class="slider-admin">
    <div class="mb-4">
      <v-card class="pa-6 mx-0">
        <v-row class="justify-space-between align-center">
          <v-col
            md="4"
            xl="4"
            cols="12"
            sm="12"
          >
            <h2>Slider</h2>
            <small style="white-space: normal;">Halaman untuk menambahkan slider di aplikasi</small>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div>
      <v-card
        class="mb-4 pa-4 rounded-lg"
        flat
        color="#E3F6FF"
        width="65%"
      >
        <v-icon
          size="20"
          color="info"
          class="mr-1 font-weight-bold"
        >
          {{ icons.mdiInformation }}
        </v-icon>
        <span
          class="font-weight-bold info--text"
        >
          Klik, Tahan dan geser slider untuk memindahkan urutan slider di Aplikasi
        </span>
      </v-card>

      <v-card
        v-if="!isLoadingCard && sliders.length < 3"
        height="315"
        color="#F5F5F5"
        class="d-flex align-center justify-center mb-4"
        style="border: 1px dashed #c4c4c4;"
        @click="showFormAdd()"
      >
        <div class="text-center">
          <v-icon size="40">
            {{ icons.mdiPlus }}
          </v-icon>
          <p>Tambah Slider</p>
        </div>
      </v-card>
      <draggable
        v-if="!isLoadingCard"
        v-model="sliders"
        tag="v-row"
        class="row wrap"

        @start="drag=true"
        @end="drag=false"
        @change="handleChange($event)"
      >
        <v-col
          v-for="(sliderItem,index) in sliders"
          :key="index"
          md="4"
          cols="12"
        >
          <v-card height="315">
            <v-card class="overflow-hidden">
              <div
                v-if="sliderItem.is_admin === '1'"
                class="pa-2 position-absolute"
                style="left: 0; z-index: 1"
              >
                <v-spacer></v-spacer>
                <template>
                  <div class="demo-space-x">
                    <v-chip
                      color="primary"
                      class="forum-chip"
                      x-small
                    >
                      Slider Superadmin
                    </v-chip>
                  </div>
                </template>
              </div>
              <v-card-title
                class="pa-2 position-absolute"
                style="right: 0;z-index: 1;"
              >
                <v-spacer></v-spacer>
                <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="white"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <a @click.prevent="showFormEdit(sliderItem.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/edit.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Edit</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item></a>
                    <a @click.prevent="confirmDestroy(sliderItem.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2 text-delete">Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-card-title>
              <div>
                <div>
                  <v-img
                    :src="sliderItem.image"
                    height="150"
                  ></v-img>
                </div>
              </div>
              <v-overlay
                :absolute="true"
                z-index="0"
                opacity="0.25"
                color="#001A33"
              ></v-overlay>
            </v-card>
            <v-card-text class="py-4">
              <div class="mb-4">
                <h2 class="font-weight-bold text-truncate mb-2">
                  {{ sliderItem.title }}
                </h2>
                <p class="font-weight-light text-truncate mb-2">
                  {{ sliderItem.description }}
                </p>
              </div>
              <div class="d-flex">
                <div class="me-2 mb-2">
                  <v-img
                    src="@/assets/icons/calendar.svg"
                    sizes="20"
                  ></v-img>
                </div>
                <span class="font-weight-light">{{ sliderItem.start_at }} - {{ sliderItem.end_at }} </span>
              </div>
              <div class="d-flex">
                <div class="me-2">
                  <v-img
                    src="@/assets/icons/clip.svg"
                    sizes="20"
                  ></v-img>
                </div>
                <span class="font-weight-light text-truncate mb-2">{{ sliderItem.link }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </draggable>
      <v-row v-else>
        <v-col
          v-for="i in 3"
          :key="i"
          md="4"
        >
          <v-skeleton-loader
            type="image"
            :loading="true"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || slider.image === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Slider': 'Edit Slider'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div class="mb-4">
          <FileInput
            v-if="dialog =='add'"
            :value="slider.image"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
          <FileInput
            v-if="dialog =='edit'"
            v-model="initialFile"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </div>
        <div>
          <v-menu
            v-model="start_date"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="slider.start_at"
                label="Waktu Mulai"
                readonly
                outlined
                dense
                v-bind="attrs"
                :rules="[validators.required]"
                clearable
                v-on="on"
                @click="autofillStartDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="slider.start_at"
              :first-day-of-week="0"
              locale="id-id"
              :min="new Date().toISOString().substr(0, 10)"
              color="primary"
              :rules="[validators.required]"
              @input="start_date = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-menu
            v-model="end_date"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="slider.end_at"
                label="Waktu Selesai"
                outlined
                dense
                v-bind="attrs"
                :rules="[validators.required]"
                clearable
                v-on="on"
                @click="autofillEndDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="slider.end_at"
              :first-day-of-week="0"
              locale="id-id"
              color="primary"
              :min="new Date().toISOString().substr(0, 10)"
              :rules="[validators.required]"
              @input="end_date = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-text-field
            v-model="slider.link"
            label="Link Slider"
            outlined
            dense
            :rules="[validators.required]"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="slider.title"
            label="Judul"
            outlined
            dense
            :rules="[validators.required]"
          ></v-text-field>
        </div>
        <div>
          <v-textarea
            v-model="slider.description"
            label="Deskripsi"
            outlined
            dense
            :rules="[validators.required]"
          ></v-textarea>
        </div>
        <div>
          <p>Saat slider di klik :</p>
          <v-radio-group v-model="slider.choice">
            <v-radio
              v-for="(choice,index) in choices"
              :key="index"
              :label="choice.title"
              :value="choice.value"
              @change="handleChoice(choice.value)"
            ></v-radio>
          </v-radio-group>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Slider"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
      <template v-slot:body>
        Apakah anda ingin menghapus Slider "{{ slider.title }}" ?
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { required } from '@core/utils/validation'
import { mdiDotsVertical, mdiImageMultipleOutline, mdiInformation, mdiPlus } from '@mdi/js'
import draggable from 'vuedraggable'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import FileInput from '../../components/FileInput.vue'
import ModalDialog from '../../components/ModalDialog.vue'

export default {
  name: 'Slider',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
    draggable,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiInformation,
      },
      validators: {
        required,
      },
      isLoadingCard: true,
      isLoadingButton: false,
      modalDialog: false,
      dialog: '',
      confirmDialog: false,
      service: 'slider',
      slider: {
        title: '',
        image: null,
        start_at: '',
        end_at: '',
        link: '',
        description: '',
        choice: null,
        order: 0,
      },
      school: [],
      school_uuid: '',
      sliders: [],
      start_date: false,
      end_date: false,
      page: 1,
      role: '',
      form: [
        {
          index: 1,
        },
        {
          index: 2,
        },
        {
          index: 3,
        },
      ],
      choices: [
        { title: 'Halaman detail slider (dalam aplikasi)', value: 'page' },
        { title: 'Link ke browser (keluar aplikasi)', value: 'link' },
      ],
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.slider.image === 'string') {
          return new File([this.slider.image], this.slider.image, { type: 'text/plain' })
        }

        return this.slider.image
      },
      set() {
        return this.slider.image
      },
    },
  },
  watch: {
    slider: {
      handler() {
        const valid = []
        const requiredField = ['start_at', 'end_at', 'link', 'title', 'description', 'choice']
        Object.entries(this.slider).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
  },
  async mounted() {
    this.getSchoolUuid()
    await this.get()
    this.deleteForm()
    this.isLoadingCard = false
  },
  methods: {
    async handleChange(value) {
      this.isLoadingButton = true
      const { uuid } = value.moved.element
      this.formData = new FormData()
      this.formData.append('type', 'school')
      this.formData.append('type_uuid[]', this.school_uuid)
      this.formData.append('order', value.moved.newIndex + 1)
      this.formData.append('oldOrder', value.moved.oldIndex + 1)

      // const data = {
      //   order: value.moved.oldIndex + 1,
      // }
      if (this.slider.choice === null) {
        this.formData.append('choice', 'page')
      } else {
        this.formData.append('choice', this.slider.choice)
      }
      await this.$services.ApiServices.update(this.service, this.formData, uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.get()
      this.isLoadingButton = false
      this.resetForm()
    },
    handleChoice(value) {
      this.slider.choice = value
    },
    resetForm() {
      this.slider.image = null
      this.slider.start_at = ''
      this.slider.end_at = ''
      this.slider.link = ''
      this.slider.title = ''
      this.slider.description = ''
      this.slider.choice = null
    },
    showFormAdd(index) {
      this.dialog = 'add'
      this.modalDialog = true
      this.slider.order = this.sliders.length + 1
      this.resetForm()
    },
    pushForm() {
      const form = [
        {
          index: 1,
        },
      ]
      this.form.push(form)
    },
    deleteForm() {
      this.form = [
        {
          index: 1,
        },
        {
          index: 2,
        },
        {
          index: 3,
        },
      ]

      this.sliders.forEach((item, key) => {
        if (item.is_admin === '0' || item.is_admin === 0) {
          this.form.splice(item, 1)
        }
      })
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.uuid
      })

      return true
    },

    async get(params = {}) {
      await this.$services.ApiServices.list(this.service, { ...params, school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.sliders = data.data
        },
      )
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('type', 'school')
      this.formData.append('type_uuid[]', this.school_uuid)
      this.formData.append('image', this.slider.image)
      this.formData.append('title', this.slider.title)
      this.formData.append('order', this.slider.order)
      this.formData.append('link', this.slider.link)
      this.formData.append('description', this.slider.description)
      this.formData.append('start_at', this.slider.start_at)
      this.formData.append('end_at', this.slider.end_at)
      this.formData.append('is_admin', 0)

      if (this.slider.choice === null) {
        this.formData.append('choice', 'page')
      } else {
        this.formData.append('choice', this.slider.choice)
      }

      await this.$services.ApiServices.add(this.service, this.formData).then(
        async ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          await this.get()
          this.deleteForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )

      this.isLoadingButton = false
      this.modalDialog = false

      this.resetForm()
    },
    showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      this.slider.uuid = uuid
      this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.slider = data.data
        this.slider.image = data.data.image.split('/').pop()
      })
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('type', 'school')
      this.formData.append('title', this.slider.title)
      this.formData.append('order', this.slider.order)
      this.formData.append('link', this.slider.link)
      this.formData.append('description', this.slider.description)
      this.formData.append('start_at', this.slider.start_at)
      this.formData.append('end_at', this.slider.end_at)
      this.formData.append('image', this.slider.image)
      this.formData.append('type_uuid[]', this.school_uuid)
      if (typeof this.slider.image === 'string') {
        this.formData.delete('image')
      }
      this.formData.append('is_admin', 0)
      if (this.slider.choice === null) {
        this.formData.append('choice', 'page')
      } else {
        this.formData.append('choice', this.slider.choice)
      }

      await this.$services.ApiServices.update(this.service, this.formData, this.slider.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.get()
      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.slider.title = data.data.title
      })
      this.slider.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.slider.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
          this.pushForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.get()
    },
    getFile(data) {
      this.slider.image = data
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    autofillStartDate() {
      this.slider.start_at = new Date().toISOString().substr(0, 10)
    },
    autofillEndDate() {
      this.slider.end_at = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
    },
  },
}
</script>

<style>
.text-delete {
  color: #e30000 !important;
}
.scroll-menu {
  position: relative;
  z-index: 3;
  overflow-y: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scroll-menu:hover {
  overflow-x: auto;
}

.demo-space-x::v-deep .v-chip {
  height: auto !important;
  padding: 10px 20px !important;
  border-radius: 24px !important;
}

.v-chip.v-chip--outlined.v-chip.v-chip::v-deep {
  background-color: #ffffff !important;
}

.demo-space-x::v-deep .forum-chip {
  padding: 2px 10px !important;
}

.btn-delete {
  color: #e30000 !important;
}

.text-forum {
  color: rgba(94, 86, 105, 0.68) !important;
}
.pointer {
  cursor: pointer;
}
.text-suspend {
  color: #e30000 !important;
}

.replyToggle {
  transition: transform 0.3s ease-in-out !important;
}

.replyToggle.rotate {
  transform: rotate(180deg);
}
.theme--light.v-skeleton-loader .v-skeleton-loader__image {
  height: 299px;
}
</style>
